import {BaseModel} from "../base/BaseModel";
import {FirmTypeEnum} from "./FirmModel";
import {InvoiceAddressModel} from "../invoiceAddress/InvoiceAddressModel";
import {CreditCardModel} from "../creditCard/CreditCardModel";
import {VehicleModel} from "../vehicle/VehicleModel";
import {SovosModel} from "../sovos/SovosModel";
import {LogModel} from "../log/LogModel";
import {AgentCommissionModel} from "../agent/AgentCommissionModel";
import {DriverModel} from "./DriverModel";
import {GroupedVehiclePricesDTO} from "../vehiclePrice/VehiclePriceModel";
import {TripModel} from "../trip/TripModel";

export enum UserTypeEnum {
    Passenger = 0,
    Driver = 1,
    Firm = 2,
    AgencyAdminUser = 3,
    Admin = 4,
    AgencyUser = 5,
    AgencyPassenger = 6,
    AgencyAdmin = 7,
}

export type UserTypeInfo = {
    name: string;
    color: string;
};

export const userTypes: Record<UserTypeEnum, UserTypeInfo> = {
    [UserTypeEnum.Passenger]: {name: "Yolcu", color: "success"},
    [UserTypeEnum.Driver]: {name: "Sürücü", color: "primary"},
    [UserTypeEnum.Firm]: {name: "Firma", color: "warning"},
    [UserTypeEnum.Admin]: {name: "Admin", color: "danger"},
    [UserTypeEnum.AgencyUser]: {name: "Acenta Kullanıcısı", color: "info"},
    [UserTypeEnum.AgencyPassenger]: {name: "Acenta Yolcusu", color: "secondary"},
    [UserTypeEnum.AgencyAdminUser]: {name: "Acenta Admin", color: "dark"},
    [UserTypeEnum.AgencyAdmin]: {name: "Acenta", color: "info"}

};

export const userTypeKeys: Record<string, UserTypeEnum> = {
    "Yolcu": UserTypeEnum.Passenger,
    "Sürücü": UserTypeEnum.Driver,
    "Firma": UserTypeEnum.Firm,
    "Acenta": UserTypeEnum.AgencyAdmin,
    "Admin": UserTypeEnum.Admin,

};


export interface UserModel extends BaseModel {
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    profilePic?: string;
    blocked?: boolean;
    type?: UserTypeEnum;
    identity?: string;
    status?: number;
    iban?: string;
    ibanOwnerName?: string;
    firmId?: number;
    agentId?: string;
    firmName?: string;
    firmType?: number;
    b2d2No?: string;
    uetdsUsername?: string;
    userKey?: string;
    nationality?: string;
    gender?: string;
    apiKey?: string;
    secret?: string;
    password?: string;
    contactPassenger?: boolean;
    isSovosUser?: boolean;
}

export interface UserSearchDTO {
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    identity?: string;
    status?: number;
    firmName?: string;
    userKey?: string;
    b2d2No?: string;
    uetdsUsername?: string;
    firmType?: FirmTypeEnum;
    type?: UserTypeEnum;
}

export interface UserPageData {
    content?: UserModel[];
    size?: number;
    totalPages?: number;
    totalElements?: number;
    number?: number;
}

export interface UserDetailDTO extends UserModel {
    zuppara?: number;
    driverBalance?: number;
    driverLicence?: string;
    criminalReport?: string;
    psycho?: string;
    src?: string;
    taxDepartment?: string;
    taxId?: string;
    address?: string;
    b2d2?: string;
    b2d2OwnerName?: string;
    travelAgency?: string;
    travelAgencyNo?: string;
    uetdsPassword?: string;
    balance?: number;
    driverRate?: number;
    creditCards?: CreditCardModel[];
    invoiceAddresses?: InvoiceAddressModel[];
    statistic?: UserStatisticDTO;
    driverLanguages?: string[];
    driverVehicles?: VehicleModel[];
    driverSovos?: SovosModel[];
    driverLogs?: LogModel[];
    agentStatistic?: UserStatisticDTO;
    agentAdmins?: UserModel[];
    agentCommissions?: AgentCommissionModel[];
    firmDrivers?: DriverModel[];
    firmVehicles?: VehicleModel[];
    firmSovos?: SovosModel[];
    driverTrips?: TripModel[];
    passengerTrips?: TripModel[];
    firmServiceRegions?: GroupedVehiclePricesDTO[];
    serviceCountryName?: string;

}

export interface UserStatisticDTO {
    callNumber?: number;
    tripNumber?: number;
    b2bTripNumber?: number;
    requestNumber?: number;
    totalPoint?: number;
    uetdsTripNumber?: number;
}
