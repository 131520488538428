export const appRoutes: any = {
    main: {
        search: "/",
        privacyPolicy: "/privacy-policy",
        eysPolicy: "/eys-policy",
        serviceUsage: "/service-usage",
    },
    admin: {
        home: "/admin/home",
        auth: {
            login: "/admin/login"
        },
        vehicle: {
            search: {
                list: "/admin/vehicle/search",
                detail: "/admin/vehicle/:id",
            },
            brand: {
                list: "/admin/vehicle/brand",
                detail: "/admin/vehicle/brand/:id",
            },
            type: {
                list: "/admin/vehicle/type",
            },
        },
        live: {
            list: "/admin/live"
        },
        tariffs: {
            b2b: {
                list: "/admin/tariffs/b2b",
            },
            normal: {
                list: "/admin/tariffs/normal",
            }
        },
        user: {
            drivers: {
                list: "/admin/user/drivers",
                detail: "/admin/user/drivers/:id",
            },
            passengers: {
                list: "/admin/user/passengers",
                detail: "/admin/user/passengers/:id",
            }
        },
        firms: {
            list: "/admin/firms",
            detail: "/admin/firm/:id",

        },
        agent: {
            list: "/admin/agent",
            detail: "/admin/agent/:id",
        },
        trip: {
            list: "/admin/trip",
            detail: "/admin/trip/detail/:id",
            comment: {
                list: `/admin/trip/comment`,
            },
        },
        b2bTrip: {
            list: `/admin/trip-b2b`,
            detail: "/admin/trip-b2b/detail/:id"
        },
        sharedTrip: {
            list: `/admin/trip-shared`,
            detail: "/admin/trip-shared/detail/:id"
        },
        driverTrip: {
            list: `/admin/trip-driver`,
            detail: "/admin/trip-driver/detail/:id"
        },
        uetdsTrip: {
            list: `/admin/trip-uetds`,
            detail: "/admin/trip-uetds/detail/:id"
        },
        call: {
            passenger: {
                list: "/admin/call/passenger",
                detail: "/admin/call/passenger/:id"
            }
        },
        request: {
            list: "/admin/request",
            detail: "/admin/request/:id"
        },
        offer: {
            list: "/admin/offer",
            detail: "/admin/offer/:id"
        },
        coupon: {
            list: "/admin/coupon",
        },
        setting: {
            list: "/admin/setting",
        },
        sovos: {
            list: "/admin/sovos",
        },
        package: {
            b2b: {
                rental: {
                    list: "/admin/package/b2b/rental",
                },
                additional: {
                    list: "/admin/package/b2b/additional",
                }
            }
        },
        invoice: {
            list: "/admin/invoice",
            detail: "/admin/invoice/:id"
        },
        route: {
            list: "/admin/route",
            detail: "/admin/route/:id",
            create: "/admin/route/create"
        },
        statistic: {
            list: "/admin/statistic",
        },
        feedback: {
            list: `/admin/feedback`,
            detail: "/admin/feedback/detail/:id"
        },
    },
    agent: {
        main: "/agent",
        home: "/agent/home",
        auth: {
            login: "/agent/login"
        },
        trip: {
            list: "/agent/trip",
            detail: "/agent/trip/detail/:id"
        },
        profile: "/agent/profile",
        statistic: {
            list: "/agent/statistic",
        },
        invoice: {
            list: "/agent/invoice",
            detail: "/agent/invoice/:id"
        },

    }
}