import axios from "axios";
import {jwtDecode} from "jwt-decode";
import {appRoutes} from "../../../routes";
import {UserTypeEnum, userTypes} from "../../model/user/UserModel";
import {ResultModel} from "../../model/base/ResultModel";
import headers from "../../helper/requestHeader/RequestHeader";


const API_URL = window.config.baseApiUrl;


const login = async (mail: any | undefined, pass: any | undefined) => {
    const email = btoa(mail);

    const response = await axios.post(
        API_URL + "/loginAdmin", {
            email: email,
            password: pass
        }
    );
    return response.data.data;
};

const loginAgentAdmin = async (mail: any | undefined, pass: any | undefined): Promise<ResultModel<any>> => {
    const email = btoa(mail);

    const response = await axios.post<ResultModel<any>>(
        API_URL + "/loginAgentAdmin", {
            email: email,
            password: pass
        }
    );

    return response.data;
}

const getAgentApiKeyFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.apiKey || null;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getAgentIdFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.agentId || null;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getAgentApiSecretFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.apiSecret || null;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getUsernameFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.name || null;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getUserAvatarFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.avatar || null;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getUserTypeFromToken = (): string | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.role as UserTypeEnum in UserTypeEnum ? userTypes[decodedJwt?.role as UserTypeEnum].name : '';
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const getUserType = (): UserTypeEnum | undefined => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return undefined;

    try {
        const decodedJwt = jwtDecode<any>(userToken);
        return decodedJwt?.role;
    } catch (error) {
        console.error("Token decoding failed:", error);
        return undefined;
    }
};

const isUserInRole = (role: string): boolean => {
    const user = getCurrentUser();
    if (!user)
        return false;
    const decodedJwt = jwtDecode<any>(user);
    return decodedJwt.scopes.findIndex((s: any) => s === role) !== -1;
}
const isAuthenticated = (): boolean => {
    return !!localStorage.getItem("token");
}
const removeToken = () => {
    return new Promise<void>((resolve) => {
        localStorage.removeItem("token");
        resolve();
    });
};
const logout = async () => {
    try {
        await removeToken();
        window.location.href = appRoutes.main.search;

    } catch (error) {
        console.error(error);
    }

};

const getCurrentUserName = (): string => {
    const user = getCurrentUser();
    if (!user)
        return "";
    const decodedJwt = jwtDecode<any>(user);
    return decodedJwt.userId;
}

const getCurrentUser = () => {
    const tokenJson = localStorage.getItem("token");
    if (!tokenJson)
        return null;
    return JSON.parse(tokenJson).token;
};
const authService = {
    login,
    logout,
    getCurrentUser,
    isAuthenticated,
    isUserInRole,
    getCurrentUserName,
    getUsernameFromToken,
    getUserTypeFromToken,
    getUserAvatarFromToken,
    loginAgentAdmin,
    getAgentApiKeyFromToken,
    getAgentApiSecretFromToken,
    getUserType,
    getAgentIdFromToken
};
export default authService;